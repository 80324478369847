// 站点信息
export const webinfo = {
    name: '鑫麦家家政服务平台',
    spimpleName: '鑫麦家',
    company: '鑫麦家家政服务平台'
}

// 正式服
export const imgapi = "https://xinmaijia.oss-cn-chengdu.aliyuncs.com/"; // OSS地址
export const adminurl = "https://sys.xmjservice.com/api/admin/";
export const weburl = "https://sys.xmjservice.com/api/";
export const uploadApi = "https://sys.xmjservice.com/api/upload_img";
export const delOssApi = "https://sys.xmjservice.com/api/del_img/";
export const domain = "https://sys.xmjservice.com";


// // 测试服API地址
// export const imgapi = "https://xinmaijia.oss-cn-chengdu.aliyuncs.com/"; // OSS地址
// export const adminurl = "https://sys.poolaiwisdom.com/api/admin/";
// export const weburl = "https://sys.poolaiwisdom.com/api/";
// export const uploadApi = "https://sys.poolaiwisdom.com/api/upload_img";
// export const delOssApi = "https://sys.poolaiwisdom.com/api/del_img/";

// 腾讯地图Key
export const txMapKey = "FXZBZ-IQ3LV-PPJPL-5UQKH-L4GTS-M2BIO";