import { adminurl, weburl, imgapi } from '@/utils/config'
import tools from "@/utils/public.js";
import store from "@/store";
const { toGet, toPost } = tools;
export default {
    // 订单列表
    async orderList(data) {
        data = {
            ...data,
            shop_id: store.getters.getShopId
        }
        return await toPost(adminurl + "order/show", data)
    },
    // 子订单列表
    async orderGoodsInfo(id) {
        return await toGet(adminurl + "order/order_goods_info/", id)
    },
    // 订单发货
    async orderTrack(data) {
        return await toGet(adminurl + "order/track_number", data)
    },
    // 修改订单剩余次数
    async updateOrderNum(data) {
        data = {
            ...data,
            shop_id: store.getters.getShopId
        }
        return await toPost(adminurl + "order/add_server_nums", data)
    },
    // 订单服务使用记录
    async appointLog(data) {
        return await toPost(adminurl + "order/sel_server_log", data)
    },


    // 预约单列表
    async serveOrderList(data) {
        data = {
            ...data,
            shop_id: store.getters.getShopId
        }
        return await toPost(weburl + "shop/server/show", data)
    },
    // 修改预约信息
    async modifyServeOrder(data) {
        return await toPost(weburl + "shop/server/modify", data)
    },
    // 取消预约单
    async cancelServe(data) {
        return await toPost(weburl + "shop/server/modify", data)
    },



    // 创建新订单
    async addOrder(data) {
        data = {
            ...data,
            shop_id: store.getters.getShopId
        }
        return await toPost(adminurl + "order/create_system_order", data)
    },
    // 创建新服务订单
    async addServeOrder(data) {
        data = {
            ...data,
            shop_id: store.getters.getShopId
        }
        return await toPost(weburl + "shop/server/add", data)
    },


    // 售后订单
    async reqOrderList(data) {
        data = {
            ...data,
            shop_id: store.getters.getShopId
        }
        return await toPost(weburl + "req/show_req_order", data)
    },
    // 售后单详情
    async reqOrderDetail(id) {
        return await toGet(weburl + "req/sel_req_order/", id)
    },
    // 取消售后订单
    async reqOrderCancel(data) {
        return await toPost(weburl + "req/modify_req_status", data)
    },
    // 取消售后订单
    async reqOrderToServe(data) {
        data = {
            ...data,
            shop_id: store.getters.getShopId
        }
        return await toPost(weburl + "req/add_server_order", data)
    },





    /* 
        积分订单 
    */
    //积分订单展示
    async integralOrderList(data) {
        data = {
            ...data,
            shop_id: store.getters.getShopId
        }
        return await toPost(weburl + "integral/order/show_order", data)
    },
    // 子订单列表
    async integralOrderGoodsInfo(id) {
        return await toGet(weburl + "integral/order/order_info/", id)
    },
    // 订单发货
    async integralOrderTrack(data) {
        return await toPost(weburl + "integral/order/track_number", data)
    },
    // 订单已完成
    async integralOrderZt(id) {
        return await toGet(weburl + "integral/order/verify_zt_order/", id)
    },



    // 物流公司列表
    async trackCode() {
        return await toGet(adminurl + "order/track_code")
    },



    // 获取保单列表
    async insureList(data) {
        data = {
            ...data,
            shop_id: store.getters.getShopId
        }
        return await toPost(weburl + "insure/show", data)
    },
    // 获取保单详情
    async insureDetail(id) {
        return await toGet(weburl + "insure/sel_baoxian/", id)
    },
}